@import 'src/assets/css/_variables.scss';

.company-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 8px 16px;
  height: 40px;
  display: flex;
  align-items: center;
  min-width: 100px;
}

.company-page {
  .input-search {
    max-width: 250px;
  }
}

.company-input-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 8px 16px;
  height: inherit;
}

.company-request-status {
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
}

.license-company-detail {
  padding: 16px;
  background: #eaeaea;
  border-radius: 8px;
}

.license-create {
  .form-create {
    padding: 12px 20px;
    max-width: 688px;
    margin: 0 auto;
  }
  .footer-form {
    padding: 20px;
    text-align: center;
  }
  .input-form {
    padding: 8px 16px;
  }
}

.radio-label {
  span {
    font-size: 16px;
    color: #272122;
  }
}

.license-detail {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px 16px;
}

.license-detail-title {
  color: #1c48e7;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 12px;
}

.license-detail-content {
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
  .labe {
    color: #000000;
    font-weight: 700;
    font-size: 16px;
  }
  &.block {
    display: block;
  }
}

.upload-file-image {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    border-radius: 8px;
    border: 1px dashed #5277f0;
    margin: 0;
    width: calc(($height-ratio-notify + 2px) * 16 / 9);
    height: calc($height-ratio-notify + 2px);
    .upload-file {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;
    }

    .text-upload {
      color: #818181;
      font-family: 'Noto Sans JP';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.5px;
    }
  }
}

.upload-file-type-5, .upload-part-type-5-none-file {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.upload-file-plate {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 100px;
    height: 125px;
  }
}

.upload-file-part-has-size {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
  }
}

.upload-file-part {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
    border: none;
    background: transparent;
  }
}

.upload-part-none-file {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 67px;
    height: 67px;
    border: none;
    background: transparent;
  }
}

.error-form-acrylic {
  .ant-form-item-explain-error {
    position: absolute;
  }
  &.ant-form-item-has-error {
    .number-input {
      border: 1px solid #ff4d4f;
    }
  }
}

.view-img-crop-none-file {
  height: 200px;
  width: 200px;
  .ant-upload-picture-card-wrapper.upload-part-none-file {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      margin-right: 0px;
      margin-bottom: 0px;
    }
  }
}

.ant-upload-picture-card-wrapper-bg {
  .ant-upload-picture-card-wrapper {
    width: unset !important;

    .ant-upload.ant-upload-select-picture-card {
      margin: 0;
      background: unset;
      border: unset;
    }
  }
}

.file-part-form {
  .ant-form-item-control-input {
    min-height: 0px;
  }
}

.btn-add-part {
  svg {
    width: 66px;
    height: 66px;
  }
}

.view-form-info-plate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-add-item-form {
  cursor: pointer;
}

.view-img-crop {
  width: 100px;
  display: flex;
  position: relative;
}

.view-img-crop-detail-template {
  width: 70px;
  height: 87.5px;
  display: flex;
  position: relative;
}

.view-img-crop-part {
  width: 200px;
}

.icon-delete-plate {
  position: absolute;
  width: 15px;
  height: 15px;
  top: -7px;
  right: -7px;
  z-index: 2;
}

.btn-edit-list-plate {
  &:hover {
    cursor: pointer;
  }
}

.thumbnail-upload {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: contain;
}

.image-part-detail {
  border-radius: 0px;
  object-fit: contain;
}

.icon-delete {
  position: absolute;
  width: 15px;
  height: 15px;
  top: -7px;
  left: calc($width-ratio-notify - 3px);
  z-index: 2;
}

.button-form-review {
  border-radius: 8px;
  background: rgba(255, 137, 0, 0.34);
  color: #2e2e2e;
  font-family: 'Noto Sans JP';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  &:hover,
  &:focus,
  &:active {
    background: rgb(255 137 0 / 65%) !important;
    color: #2e2e2e;
    outline: none;
    box-shadow: none;
    border: none;
    border-color: unset;
  }
}

.form-label-phone {
  color: #2e2e2e;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin: 0 0 4px !important;
  display: inline-block;
  span {
    display: inline-block;
    margin-right: 0;
    color: #f42929;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.bg-blue {
  background: #e6eeff;
  border-radius: 8px;
}

.number-input {
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
  padding: 12px 16px;
  color: #272122;
  font-size: 16px;
  min-height: 48px;
  height: 48px;
  font-family: 'Noto Sans JP', sans-serif;
  list-style: none;
  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  &:hover {
    border-color: #40a9ff;
    border-inline-end-width: 1px;
  }
  &:focus {
    border-color: #5277f0;
    border-inline-end-width: 1px;
    outline: 0;
  }
}

.container-chart-revenue {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  padding-inline: 80px;

  .header-chart {
    padding-top: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #223354;
    padding-inline: 16px;
  }
  .wrap-info-statistic {
    display: flex;
    padding-left: 110px;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 35px;
    .line {
      height: 105px;
      border: 1px solid #e6edff;
      margin-inline: 38px;
    }
    .info-statistic {
      padding-inline: 25px;
      padding-block: 27px;
      border-radius: 5px;
      border: 1px solid #c0c0c0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 33px;
      .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
      }
      .statistic {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
  .title-yAxis {
    padding-inline: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #8d8d8d;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  .wrap-chart {
    padding-inline: 50px;
    .chart-revenue {
      position: relative;
    }
  }
}

.list-order-statistic {
  width: 82%;
}

.title-no-data {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.select-part-disable {
  .ant-select-selector {
    background: #eaf0ff !important;
    border-color: #5277f0 !important;
    color: #272122 !important;
  }
  .ant-select-arrow {
    display: none;
  }
}

.image-crop-template {
  height: 450px;
  width: 300px;
}

.upload-file-template {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    height: 450px;
    width: 300px;
  }
}

.wrap-design-image-template {
  padding-top: 23px;
  padding-bottom: 52px;
  border: 1px solid #000;
  background: #fff;
}

.wrap-design-image-template-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Noto Sans JP';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.image-template-preview {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.image-template-preview-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap-total-revenue {
  display: flex;
  justify-content: flex-end;
  padding: 16px 0px;
  border-bottom: 1px solid #f0f0f0;
  .view-title-total-price {
    width: 20%;
    display: flex;
    justify-content: right;
    padding-right: 16px;
  }
  .view-right-title-total-price {
    width: 15%;
  }
  .title-total-price {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding-right: 50px;
  }
  .total-price-revenue {
    color: var(--Grey-Color-500, #2e2e2e);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.cover-image-template {
  border: 1px solid #000000;
}
